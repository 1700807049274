<template>
    <div class="container-fluid">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col ml-n2">
                                <h4 class="mb-1">
                                    <a>{{$t('accountsTransfers.show.accountsTransfersOperation')}} <strong> {{item.code}}</strong></a>
                                </h4>
                            </div>
                            <div class="col-auto">
                                <router-link :to="path+'/'+item.id + '/edit'" class="btn btn-sm btn-primary" :title="$t('accountsTransfers.show.Modification')" v-if="$user.admin || $user.role.safes_transfers_edit">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="fal fa-credit-card-front"></i>
                            {{$t('accountsTransfers.show.accountsTransfersOperationDetails')}}
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-9">
                                <table class="table table-sm table-nowrap customed-table-small">
                                    <tbody>
                                        <tr>
                                            <th scope="row">{{$t('accountsTransfers.show.operationCode')}} </th>
                                            <td>{{item.code}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('accountsTransfers.show.transferDate')}}</th>
                                            <td>{{$dateFormat(item.date)}}  </td>
                                        </tr>
                                         <tr>
                                            <th scope="row">{{$t('accountsTransfers.show.user')}}</th>
                                            <td>{{item.user ? item.user.name : '--'}}</td>
                                        </tr>

                                        <tr>
                                            <th scope="row">{{$t('accountsTransfers.show.fromAccount')}}</th>
                                            <td>
                                                <router-link :to="'/customers/' + item.from_customer.id" v-if="item.from_customer && item.from_client_type == 0">{{ item.from_customer.name}}</router-link>
                                                <router-link :to="'/suppliers/' + item.from_supplier.id" v-if="item.from_supplier && item.from_client_type == 1">{{ item.from_supplier.name}}</router-link>
                                                <router-link :to="'/partners/' + item.from_partner.id" v-if="item.from_partner && item.from_client_type == 2">{{ item.from_partner.name}}</router-link>
                                                <router-link :to="'/accounts/' + item.from_account.id" v-if="item.from_account && item.from_client_type == 3">{{ item.from_account.name}}</router-link>
                                                {{!item.from_customer && !item.from_supplier && !item.from_partner && !item.from_account ? '--' : ''}}
                                            </td>
                                        </tr>
                                         <tr>
                                            <th scope="row">{{$t('accountsTransfers.show.toAccount')}}</th>
                                            <td>
                                                <router-link :to="'/customers/' + item.to_customer.id" v-if="item.to_customer && item.to_client_type == 0">{{ item.to_customer.name}}</router-link>
                                                <router-link :to="'/suppliers/' + item.to_supplier.id" v-if="item.to_supplier && item.to_client_type == 1">{{ item.to_supplier.name}}</router-link>
                                                <router-link :to="'/partners/' + item.to_partner.id" v-if="item.to_partner && item.to_client_type == 2">{{ item.to_partner.name}}</router-link>
                                                <router-link :to="'/accounts/' + item.to_account.id" v-if="item.to_account && item.to_client_type == 3">{{ item.to_account.name}}</router-link>
                                                {{!item.to_customer && !item.to_supplier && !item.to_partner && !item.to_account ? '--' : ''}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-3">
                                <div class="price-show">
                                    <div class="price-content">
                                        <p>{{$t('accountsTransfers.show.transfersAmount')}}</p>
                                        <h1>{{$moneyFormat(item.cost)}} </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <showFile/>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import showFile from '../elements/show/showFile.vue';

    export default {
        data() {
            return {
                path: '/accountsTransfers',
                item: {},

            }
        },
        mounted() {
            this.getitem();
        },
        methods: {
            getitem() {
                axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id))
                    .then(response => {
                        this.item = response.data;
                    });
            }
        },
        components: {
            showFile,
        }
    };

</script>
